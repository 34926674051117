import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default props => (
  <article
    className={`post-card ${props.postClass} ${
      props.node.frontmatter.thumbnail ? `with-image` : `no-image`
    }`}
  >

    <div style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}

      {props.node.frontmatter.thumbnail &&
        <GatsbyImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          // layout="constrained"
          // You can optionally force an aspect ratio for the generated image
          // This is a presentational image, so the alt should be an empty string
          alt=""
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          image={props.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
          // formats={["auto", "webp"]}
        />
      }

      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <Link to={props.node.fields.slug} className="post-card-link">
          <div className="post-card-content">
            <h2 className="post-card-title">
              {props.node.frontmatter.title || props.node.fields.slug}
            </h2>
          </div>
        </Link>
      </div>
    </div>



  </article>
)

// ${props.count % 3 === 0 && `post-card-large`}
