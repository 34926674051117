import React, {useState} from 'react';
import * as signupStyles from "./signup.module.css"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Helmet from "react-helmet"

function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
  
export default function Signup() {
    const { executeRecaptcha } = useGoogleReCaptcha()
    console.log('executeRecaptcha', executeRecaptcha)

    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(true);
    const [mode, setMode] = useState(0);

    const handleEmailChange = (e) => setEmail(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setMode(1)

        try {
            console.log("handleSubmit", e)

            if (!executeRecaptcha) {
                console.log("!executeRecaptcha")
                throw "Not Ready"
            }

            // VALIDATE
            if (!validateEmail(email)) {
                console.log("not valid")
                setValid(false)
                setMode(0)
                return
            }

            const token = await executeRecaptcha('signup')
            console.log('token', token)

            const data = JSON.stringify({email, token})

            console.log('data', data)

            const submitResult = await fetch('https://cwzrokjccj.execute-api.us-east-1.amazonaws.com/001/signup', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-type': 'application/json'
                },
                body: data 
            })

            const finalResult = await submitResult.json()

            console.log('finalResult', finalResult);

            if(finalResult.result == "OK") {
                setMode(2)
                return
            } 

            throw "Unexpected Result"
        
        } catch(e) {
            console.log(e)
            setMode(3)
        }
    };

    return (
        <div className={signupStyles.container}>
        <Helmet>
            <link href="https://use.fontawesome.com/releases/v5.15.2/css/all.css"  crossorigin="anonymous" rel="stylesheet" type="text/css" />
        </Helmet>
        {
            {
            0: <div>
                {/* <div className={signupStyles.title}>Join my inner circle,</div> */}
                <div className={signupStyles.descripton}>Join my inner circle, for rare glimpses into new creations!</div>
                <form onSubmit={handleSubmit} style={{marginBottom: 0}}>
                    <div className={signupStyles.inputRow}>
                        <div className={signupStyles.inputContainer}>
                            <input className={signupStyles.emailInput} type="email" name="email" id="email" value={email} onChange={handleEmailChange} placeholder="Email Address" required/>
                            <button type="submit" className={signupStyles.submitButton}>  
                            <i class="fas fa-chevron-right"></i>
                            <span class="sr-only">Join</span></button>
                        </div>
                    </div>

                    {!valid && <span className={signupStyles.validation}>Please enter a valid email address.</span>}
                </form>
                {/* <div className={signupStyles.privacy}></div> */}
            </div>,
            1: <div>
                <div className={signupStyles.working}>Working...</div>
            </div>,
            2: <div>
                <div className={signupStyles.done}>Done. Thank you for joining!</div>
            </div>,
            3: <div>
                <div className={signupStyles.oops}>Oops, unable not join, refresh and try agian.</div>
            </div>
            }[mode]
        }
        </div>
    )
  
}
