import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"
import Signup from "../components/signup"

import "../utils/styles.css"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0


  return (
    <Layout title={siteTitle}>
      <SEO
        title="Portfolio"
        keywords={[
          `Parametric`,
          `Wall Sculpture`,
          `Contemporary Art`,
          `Dimensional Wall Art`,
          `3D Wall Art `,
          `Modern Art`,
          `Flower Art`,
          `Mosaic Art`,
          `Wood Wall Art`,
          `Sacred Geometry`,
          `Abstract Art`,
          `Wall Object`,
          `3D Printing`,
          `Mandala Art`,
          `Geometric Art`,
          `Trippy Art`,
        ]}
      />
 
      <div className="header-wrapper">
        <GatsbyImage image={data.bannerImage.childImageSharp.gatsbyImageData} alt="Header background" className="header-background" objectPosition="top center" />
      </div>

      <header className="page-head" style={{padding: "5vh 0 10vh 0", maxWidth: "800px"}}>
          <h2 style={{fontSize: "4rem"}}>
            Mesmerizing sculptures that blend digital precision with organic beauty.
          </h2>
        </header>

      {/* <div style={{ display: "flex", justifyContent: "center", marginBottom: "50px"}}><Signup/></div> */}

      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
  )
}



const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    bannerImage: file(relativePath: { eq: "header1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1360)
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/content/blog/"}}
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 640)
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
